import { Box, Typography } from "@mui/material";

interface IFooterContent {
  year: number;
  companyName: string;
  version: string;
}

export default function FooterContent({
  year,
  companyName,
  version,
}: IFooterContent) {
  return (
    <Box
      sx={{
        display: "flex",
        mt: 3,
      }}
    >
      <Typography sx={{ display: "block" }} variant="caption">
        © {companyName} {year} | v{version}
      </Typography>
    </Box>
  );
}
