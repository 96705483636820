import axios from "axios";

import { Credentials } from "evrythng";

export function getEvrythngApiUrl({
  apiVersion,
  region,
}: {
  apiVersion: number;
  region: string;
}): string {
  if (apiVersion === 1 && region === "eu") {
    return "https://api-eu.evrythng.com";
  }
  if (apiVersion === 1 && region === "us") {
    return "https://api.evrythng.com";
  }
  if (apiVersion === 2 && region === "eu") {
    return "https://api.eu.evrythng.io/v2";
  }
  return "https://api.evrythng.io/v2";
}

export async function productCloudAxiosRequest<T>({
  credentials,
  url,
  method,
  document,
}: {
  credentials: Credentials;
  url: string;
  method: string;
  document?: any;
}): Promise<T | Error> {
  const apiUrl = getEvrythngApiUrl({
    apiVersion: credentials.apiVersion,
    region: credentials.region,
  });

  const { data, status, statusText } = await axios<T>({
    url: `${apiUrl}${url}`,
    method,
    headers: {
      Authorization: credentials.apiKey,
    },
    data: document,
  });
  if (status >= 300) {
    const errorMessage = `Request to ${url} failed with ${status}, ${statusText}`;
    return Error(errorMessage);
  }
  return data;
}
