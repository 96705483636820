import { useTheme } from "@mui/material/styles";

import { Box, Container } from "@mui/material";

export default function Footer({ children }: { children?: React.ReactNode }) {
  const theme = useTheme();

  return (
    <Box
      sx={{
        py: 3,
        color: theme.palette.secondary.contrastText,
        backgroundColor: theme.palette.secondary.dark,
        marginTop: "auto",
      }}
    >
      <Container>{children}</Container>
    </Box>
  );
}
