/* eslint-disable import-newlines/enforce ,multiline-ternary */
import { useState, useEffect } from "react";

import { ThngDocument, ProductDocument } from "evrythng";

import { Helmet, HelmetProvider } from "react-helmet-async";
import {
  Box,
  Typography,
  Container,
  CircularProgress,
  Alert,
} from "@mui/material";
import ThemeCustomization from "./themes";

import config from "./config";

import Header from "./components/Header";
import Footer from "./components/Footer";
import FooterContent from "./components/FooterContent";
import DataTable from "./components/DataTable";
import DebugInfo from "./components/DebugInfo";

import { productCloudAxiosRequest } from "./api";

import { defaultDebugInfoMap } from "./data";

import formatDate, {
  generateDateFromString,
  generateShortStringFromDate,
} from "./utils/formatDate";

function App() {
  const queryParameters = new URLSearchParams(window.location.search);
  const thngId = queryParameters.get("thng");
  const productId = queryParameters.get("product");
  const token = queryParameters.get("token");

  const [pageLoadTime, setPageLoadtime] = useState<Date | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const [batchInfoData, setBatchInfoData] = useState(defaultDebugInfoMap);

  const getThng = async () => {
    try {
      if (token && thngId && productId) {
        setLoading(true);
        setError(null);

        const apiCredentials = {
          apiKey: token as string,
          apiVersion: config.apiSettings.version,
          region: config.apiSettings.region,
        };

        const [thngResult, productResult] = await Promise.allSettled([
          productCloudAxiosRequest({
            credentials: apiCredentials,
            url: `/thngs/${thngId}`,
            method: "get",
          }),
          productCloudAxiosRequest({
            credentials: apiCredentials,
            url: `/products/${productId}`,
            method: "get",
          }),
        ]);

        if (thngResult.status === "fulfilled") {
          addThngInfoToBatchInfo(thngResult.value as ThngDocument);
        } else if (thngResult.status === "rejected") {
          // setError("Invalid Thng Id.");
          setError("(error: API call error)");
        }

        if (productResult.status === "fulfilled") {
          addProductInfoToBatchInfo(productResult.value as ProductDocument);
        } else if (productResult.status === "rejected") {
          // setError("Invalid Product Id.");
          setError("(error: API call error)");
        }
      } else {
        setError("(error: issue with parameters)");
      }
      setLoading(false);
    } catch (thngError) {
      setError("Failed to get Batch Information.");
      setLoading(false);
    }
  };

  const addThngInfoToBatchInfo = (thngData: ThngDocument) => {
    setBatchInfoData((prevBatchInfo) => {
      prevBatchInfo.set("Expiry Date", {
        ...prevBatchInfo.get("Expiry Date")!,
        value: generateShortStringFromDate(
          generateDateFromString(thngData?.customFields["batch-expiry"])
        ),
      });

      prevBatchInfo.set("Manufacturing Date", {
        ...prevBatchInfo.get("Manufacturing Date")!,
        value: generateShortStringFromDate(
          generateDateFromString(
            thngData?.customFields["batch-manufacturer_date"]
          )
        ),
      });

      prevBatchInfo.set("Batch ID", {
        ...prevBatchInfo.get("Batch ID")!,
        value:
          thngData?.identifiers["gs1:10"]?.toUpperCase() || "Empty Batch ID",
      });

      return new Map(prevBatchInfo);
    });
  };

  const addProductInfoToBatchInfo = (productData: ProductDocument) => {
    setBatchInfoData((prevBatchInfo) => {
      prevBatchInfo.set("Product Name", {
        ...prevBatchInfo.get("Product Name")!,
        value: productData?.name || "No Product Name",
      });

      prevBatchInfo.set("Brand Name", {
        ...prevBatchInfo.get("Brand Name")!,
        value: productData?.brand || "No Brand",
      });

      prevBatchInfo.set("GTIN", {
        ...prevBatchInfo.get("GTIN")!,
        value: productData?.identifiers?.["gs1:01"] || "No GTIN",
      });

      prevBatchInfo.set("Manufacturing Name/ Address", {
        ...prevBatchInfo.get("Manufacturing Name/ Address")!,
        value:
          productData?.customFields?.["manufacturer-address"] ||
          "No Manufacturing Address",
      });

      prevBatchInfo.set("Manufacturing License Number", {
        ...prevBatchInfo.get("Manufacturing License Number")!,
        value:
          productData?.customFields?.["manufacturer-licence_number"] ||
          "No Manufacturing License Number",
      });

      return new Map(prevBatchInfo);
    });
  };

  useEffect(() => {
    console.log(
      "Powered by the Digimarc Illuminate Platform. Find out more at https://www.digimarc.com "
    );
    const currentTime = new Date();
    setPageLoadtime(currentTime);
    getThng();
  }, []);

  return (
    <HelmetProvider>
      <ThemeCustomization>
        <Helmet>
          <title>AstraZeneca - {config.pageTitle}</title>
        </Helmet>
        <Box
          id="app"
          sx={{
            display: "flex",
            flexDirection: "column",
            minHeight: "100vh",
          }}
        >
          <Header pageTitle={config.pageTitle}></Header>

          <Container>
            {loading && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  pt: 10,
                }}
              >
                <CircularProgress />
              </Box>
            )}
            {!loading && !error && (
              <>
                <Box sx={{ my: 5 }}>
                  <DataTable
                    title="Batch Information"
                    data={Array.from(batchInfoData.values())}
                    ariaLabel="Information Table about the current batch."
                  />
                </Box>

                {config.disclaimerMessage && (
                  <Typography sx={{ my: 5 }}>
                    {config.disclaimerMessage}
                  </Typography>
                )}
              </>
            )}

            {error && !loading && (
              <Alert sx={{ my: 5 }} severity="error">
                Something went wrong with the request to access this page{" "}
                {error}
              </Alert>
            )}
          </Container>

          <Footer>
            {!loading && (
              <DebugInfo
                debugInfo={{
                  accountId: {
                    label: "Account",
                    value: config.accountId,
                    errorMessage: "No Account Set",
                  },
                  thngId: {
                    label: "Thng",
                    value: thngId,
                    errorMessage: "",
                  },
                  productId: {
                    label: "Product",
                    value: productId,
                    errorMessage: "",
                  },
                  requestTime: {
                    label: "Page Load Time",
                    value: pageLoadTime ? formatDate(pageLoadTime) : "",
                    errorMessage: "",
                  },
                }}
              />
            )}

            <FooterContent
              companyName="AstraZeneca"
              year={2023}
              version={config.version}
            />
          </Footer>
        </Box>
      </ThemeCustomization>
    </HelmetProvider>
  );
}

export default App;
