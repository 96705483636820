import { useState, useRef, useEffect } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  Container,
  Divider,
} from "@mui/material";

import Logo from "../Logo";

interface IHeaderProps {
  pageTitle: string;
}

export default function Header({ pageTitle }: IHeaderProps) {
  const [appBarHeight, setAppBarHeight] = useState(0);
  const appBarRef = useRef<HTMLDivElement>(null);

  const theme = useTheme();

  useEffect(() => {
    const handleResize = () => {
      if (appBarRef.current) {
        setAppBarHeight(appBarRef.current.offsetHeight);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [appBarRef]);

  return (
    <>
      <AppBar
        ref={appBarRef}
        position="fixed"
        elevation={0}
        color="inherit"
        sx={{
          borderBottom: `1px solid ${theme.palette.divider}`,
        }}
      >
        <Toolbar>
          <Container>
            <Box
              sx={{
                display: "flex",
                justifyContent: { md: "space-between" },
                alignItems: "center",
                flexDirection: { xs: "column", md: "row" },
                flexGrow: { md: 1 },
              }}
            >
              <Logo />
              <Divider
                sx={{
                  width: "100%",
                  display: { xs: "block", md: "none" },
                  my: 2,
                }}
              />
              <Typography variant="h4">{pageTitle}</Typography>
            </Box>
          </Container>
        </Toolbar>
      </AppBar>
      <Box
        sx={{
          minHeight: appBarHeight,
        }}
      ></Box>
    </>
  );
}
