/* eslint-disable multiline-ternary */
export default function formatDate(d: Date) {
  return `${d.getFullYear()}-${padNumber(d.getMonth())}-${padNumber(
    d.getDate()
  )} ${padNumber(d.getHours())}:${padNumber(d.getMinutes())}:${padNumber(
    d.getSeconds()
  )}.${d.getMilliseconds()} ${getTimeZoneString(d)}`;
}

function padNumber(num: number) {
  return String(num).padStart(2, "0");
}

function getTimeZoneString(d: Date) {
  const timezoneOffset = d.getTimezoneOffset();
  // Convert the offset to hours and minutes
  const offsetHours = Math.floor(Math.abs(timezoneOffset) / 60);
  const offsetMinutes = Math.abs(timezoneOffset) % 60;

  // Determine the sign of the offset
  const offsetSign = timezoneOffset > 0 ? "-" : "+";

  // Format the offset as a string
  const offsetString = `${offsetSign}${padNumber(offsetHours)}:${padNumber(
    offsetMinutes
  )}`;

  return offsetString;
}

/**
 * Generate date  given YYYYMM or YYYYMMDDD
 */
export function generateDateFromString(dateString?: string): Date | undefined {
  if (dateString) {
    const year = parseInt(dateString.slice(0, 4));
    const month = parseInt(dateString.slice(4, 6));
    const day = parseInt(dateString.slice(6, 8));

    const date = new Date(year, month - 1, day || 1);

    return date;
  }
  return undefined;
}

export function generateShortStringFromDate(date?: Date): string {
  if (!date) {
    return "No Date Provided";
  }

  if (!(date instanceof Date) || date.toString() === "Invalid Date") {
    return "Invalid Date";
  }

  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  const formattedDate = `${padNumber(month)}/${year}`;
  return formattedDate;
}
