export type DebugInfoValue = {
  name: DebugInfoExpectedFields;
  value: string;
  label: string;
  html: boolean;
};

export type DebugInfoExpectedFields =
  | "GTIN"
  | "Product Name"
  | "Brand Name"
  | "Batch ID"
  | "Expiry Date"
  | "Manufacturing Date"
  | "Manufacturing License Number"
  | "Manufacturing Name/ Address";

export const defaultDebugInfoMap: Map<DebugInfoExpectedFields, DebugInfoValue> =
  new Map([
    [
      "GTIN",
      {
        name: "GTIN",
        label: "Unique product identification code",
        value: "",
        html: false,
      },
    ],
    [
      "Product Name",
      {
        name: "Product Name",
        label: "Generic name of the drug",
        value: "",
        html: false,
      },
    ],
    [
      "Brand Name",
      {
        name: "Brand Name",
        label: "Brand name",
        value: "",
        html: false,
      },
    ],
    [
      "Manufacturing Name/ Address",
      {
        name: "Manufacturing Name/ Address",
        label: "Name and address of the manufacturer",
        value: "",
        html: true,
      },
    ],
    [
      "Batch ID",
      {
        name: "Batch ID",
        label: "Batch number",
        value: "",
        html: false,
      },
    ],
    [
      "Manufacturing Date",
      {
        name: "Manufacturing Date",
        label: "Date of manufacturing",
        value: "",
        html: false,
      },
    ],
    [
      "Expiry Date",
      {
        name: "Expiry Date",
        label: "Date of expiry",
        value: "",
        html: false,
      },
    ],

    [
      "Manufacturing License Number",
      {
        name: "Manufacturing License Number",
        label: "Manufacturing license number",
        value: "",
        html: false,
      },
    ],
  ]);
