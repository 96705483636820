/* eslint-disable multiline-ternary */
import { Box, Typography } from "@mui/material";

type DebugInfoStructure = {
  label: string;
  value: string | null;
  errorMessage: string;
};

type DebugInfoObject = {
  [key: string]: DebugInfoStructure;
};

interface IDebugInfo {
  debugInfo: DebugInfoObject;
}

export default function DebugInfo({ debugInfo }: IDebugInfo) {
  return (
    <Box>
      <ul style={{ listStyleType: "none", margin: 0, padding: 0 }}>
        {Object.entries(debugInfo).map(([key, value]) => (
          <li key={key}>
            <Typography variant="caption">
              {value.label} : {value.value ? value.value : value.errorMessage}
            </Typography>
          </li>
        ))}
      </ul>
    </Box>
  );
}
